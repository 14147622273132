<template>
  <q-page class="q-pa-lg">
    <q-select
      v-model="form.operator"
      :label="$t('bus.ticket.operator')"
      :options="['Stagecoach']"
    />
    <q-select
      v-model="form.area"
      :label="$t('bus.ticket.area')"
      :options="areas"
      @input="val => form.zone = ''"
    />
    <div class="need-help">
      {{ $t('tickets.need_help') }}<br>
      <a href="https://tiscon-maps-stagecoachbus.s3.amazonaws.com/ZoneMaps/North Scotland/Highlands Zone Map 2018.pdf" target="_blank">{{ $t('tickets.view_map') }}</a>
    </div>
    <q-select
      ref="zoneSelect"
      v-model="form.zone"
      :label="$t('bus.ticket.zone')"
      :options="zones"
      :disable="zones.length === 0"
    />

    <q-select
      v-model="form.duration"
      :label="$t('bus.ticket.duration')"
      :options="durations"
    />

    <q-select
      v-model="form.class"
      :label="$t('bus.ticket.class')"
      :options="classes"
    />
    <div v-if="total" class="text-right">
      <span> {{ $t('total_cost') }}: {{ total }} </span>
    </div>

    <m-unified-users v-model="people" />
    <q-footer v-if="$q.platform.is.mobile" class="bg-white shadow-5 q-pa-sm q-py-md row justify-around">
      <m-guest-blocker v-if="hasRole('guest')" />
      <button v-else class="buy-tickets bg-primary" :class="filtered.length === 1 ? '' : 'disabled'" @click="onSubmit">
        {{ $t('bus.ticket.buy') }}
      </button>
    </q-footer>
  </q-page>
</template>

<script>
import Dinero from 'dinero.js'
import { mapGetters } from 'vuex'
import { MUnifiedUsers, MGuestBlocker } from 'components/'
import authentication from 'mixins/authentication'

export default {
  name: 'PublicTransportPurchase',
  components: { MUnifiedUsers, MGuestBlocker },
  mixins: [authentication],
  data () {
    return {
      form: {
        operator: 'Stagecoach',
        area: '',
        duration: '',
        class: '',
        zone: ''
      },
      classes: ['Single', 'Group']
    }
  },
  computed: {
    ...mapGetters({
      tickets: 'publicTransport/tickets',
      user: 'userLookup',
      stash: 'ondemand/stash'
    }),
    areas () {
      const areas = []
      this.tickets.forEach(ticket => {
        if (!areas.includes(ticket.area)) {
          areas.push(ticket.area)
        }
      })
      return areas
    },
    durations () {
      const days = []
      this.tickets.forEach(ticket => {
        if (!ticket.type.includes('group')) {
          const option = {
            label: `${ticket.days} day(s) (${ticket.type})`,
            value: ticket.days
          }
          if (!days.some(day => day.label === option.label)) {
            days.push(option)
          }
        }
      })
      return days
    },
    filtered () {
      // Ticket list is dynamically generated so we want to eventually end up with a single ticket for the user to buy
      // Order of filters: Area -> Duration -> Zone (if applicable) -> Class
      const filteredByArea = this.tickets.filter(ticket => ticket.area === this.form.area)

      const filterByDuration = filteredByArea.filter(ticket => {
        return ticket.days === this.form.duration.value
      })

      const filterByZone = filterByDuration.filter(ticket => {
        return ticket.zone === this.form.zone
      })

      const classFilter = ticket => {
        if (this.form.class === 'Group') {
          return ticket.type.includes('group')
        } else {
          return !ticket.type.includes('group')
        }
      }

      // Not every area has zones so we skip the zone filter if not

      const filterByClass = this.form.zone ? filterByZone.filter(classFilter) : filterByDuration.filter(classFilter)

      if (this.form.class) {
        return filterByClass
      }

      if (this.form.zone) {
        return filterByZone
      }
      if (this.form.duration) {
        return filterByDuration
      }
      return filteredByArea || []
    },
    zones () {
      const zones = []
      if (this.form.area) {
        this.tickets.forEach(ticket => {
          if (!zones.includes(ticket.zone) && ticket.zone && (ticket.area === this.form.area)) {
            zones.push(ticket.zone)
          }
        })
      }
      return zones
    },
    total () {
      if (this.filtered.length === 1) {
        return Dinero({ amount: this.filtered[0].price, currency: 'GBP' }).toFormat('$0.00')
      } else {
        return 0
      }
    },
    people: {
      get () {
        return {
          traveller: this.stash.parameters.traveller,
          requester: this.stash.parameters.requester
        }
      },
      set (val) {
        this.$store.dispatch('ondemand/parameters', { requester: val.requester })
        this.$store.dispatch('ondemand/parameters', { traveller: val.traveller })
      }
    }
  },
  watch: {
    filtered: {
      immediate: true,
      handler (val) {
        if (val.length === 1) this.$store.dispatch('ondemand/stash', { ticket: this.filtered[0] })
      }
    }
  },
  created () {
    this.$store.dispatch('ondemand/parameters', { request: this.user, traveller: this.user })
    this.$store.dispatch('publicTransport/getTickets')
  },
  methods: {
    onSubmit () {
      if (this.filtered.length !== 1) return
      this.$store.dispatch('ondemand/stash', { ticket: this.filtered[0] })
      this.$router.push({ name: 'ondemand-bus-summary' })
    }
  }

}
</script>

<style lang="stylus" scoped>
.buy-tickets
  border-radius 5px
  border none
  padding 5px
  width 100%
  height 50px
  text-transform uppercase
  font-weight 700
  &.disabled
    opacity 0.5

.need-help
  margin 20px 0 10px
  font-size 90%
  a
    color convert(brandColor)
    font-weight 500
    text-decoration underline
</style>
